<template>
  <div class="serialnumber">
    <div class="serialnumberform" v-if="step != 'third'">
      <span class="h3">SERİ NO EKLE</span>
      <!-- <span
      class="alert"
      v-if="isPointUpload == 'false'"
    >Seri numarası girmeden önce lütfen BES Hesabı oluşturun.</span> -->
      <div v-if="successMessage.length == 0">
        <div class="col-md-12 pd0">
          <div class="form-group code">
            <input
              autocomplete="nope"
              type="text"
              class="brand-input"
              v-model="code"
              maxlength="25"
              placeholder="SERİ NUMARASI GİRİNİZ"
              :disabled="step != 'first'"
            />

            <span class="error" :class="{ show: notValid.includes('code') }"
              >Lütfen seri numarası giriniz.</span
            >
            <span class="small"
              >Montajı yapılan ürüne ait seri no’yu giriniz</span
            >
          </div>
        </div>
        <div class="col-md-6 pd0 pd-r-5">
          <div class="form-group brand-select">
            <v-select
              autocomplete="nope"
              :options="citys"
              v-model="baymakCityId"
              class="brand-select"
              placeholder="İL"
              :clearable="false"
              :disabled="step != 'first'"
              :filter="getCity"
              :searchable="false"
            >
              <template v-slot:no-options>Şehrinizi bulamadık..</template>
            </v-select>

            <span
              class="error"
              :class="{ show: notValid.includes('baymakCityId') }"
              >Lütfen il seçiniz.</span
            >
          </div>
        </div>
        <div class="col-md-6 pd0 pd-l-5">
          <div class="form-group brand-select">
            <v-select
              autocomplete="nope"
              :options="districts"
              v-model="districtId"
              class="brand-select"
              placeholder="İLÇE"
              :clearable="false"
              :disabled="step != 'first'"
              :filter="getDistricts"
              :searchable="false"
            >
              <template slot="option" slot-scope="option">
                {{ option.label }}
              </template>
              <template v-slot:no-options>İlçenizi bulamadık..</template>
            </v-select>

            <span
              class="error"
              :class="{ show: notValid.includes('districtId') }"
              >Lütfen ilçe seçiniz.</span
            >
          </div>
        </div>
        <div class="col-md-12 small small-2">
          Montajı yapılan ürüne ait il-ilçe bilgisini giriniz
        </div>

        <div class="clearfix"></div>
        <div
          class="form-group text-left contractform"
          v-if="showControlFormCheckbox && step == 'second'"
        >
          <label for="kvkk" class="btn-radio">
            <input
              autocomplete="nope"
              type="checkbox"
              name="a2"
              id="kvkk"
              v-model="isCombiInstallationControlForm"
            />

            <u @click="contractModal"
              >YOĞUŞMALI KOMBİ MONTAJ KONTROL FORMU'nu</u
            >
            okudum onaylıyorum.
            <span class="checkmark"></span>
          </label>
          <span
            class="error"
            :class="{
              show: notValid.includes('isCombiInstallationControlForm'),
            }"
            >Lütfen onaylayınız.</span
          >
        </div>
        <span class="error show" v-if="error != null">{{ error }}</span>
        <button
          type="button"
          class="brand-button"
          @click="firstStep"
          v-if="step == 'first'"
        >
          İLERLE
          <Loading v-if="isProcessing"></Loading>
        </button>

        <!-- Servis Formu -->
        <!-- <div v-if="step=='second'"> -->
        <div v-if="false">
          <div class="col-md-12 col-xs-12 service-form">
            <span class="h4">Servis Talep Formu</span>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input"
                maxlength="25"
                v-model="serviceForm.serviceRequestName"
                placeholder="Ad"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestName') }"
                >Lütfen adınızı giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-l-5">
            <div class="form-group">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input"
                v-model="serviceForm.serviceRequestSurname"
                maxlength="25"
                placeholder="Soyad"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestSurname') }"
                >Lütfen soyadınızı giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group brand-select">
              <the-mask
                mask="0 (###) ### ## ##"
                v-model="serviceForm.serviceRequestPhone"
                class="brand-input"
                type="phone"
                placeholder="Telefon"
              ></the-mask>

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestPhone') }"
                >Lütfen geçerli bir cep telefonu numarası giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group brand-select">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input email"
                v-model="serviceForm.serviceRequestEmail"
                placeholder="E-Posta"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestEmail') }"
                >Lütfen e-posta adresinizi giriniz</span
              >
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group brand-select">
              <v-select
                autocomplete="nope"
                :options="serviceRequestCitys"
                v-model="serviceRequestCity"
                class="brand-select"
                placeholder="İl"
                :clearable="false"
              >
                <template v-slot:no-options>Şehrinizi bulamadık..</template>
              </v-select>

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestCity') }"
                >Lütfen il seçiniz.</span
              >
            </div>
          </div>

          <div class="col-md-6 pd0 pd-l-5">
            <div class="form-group brand-select">
              <v-select
                autocomplete="nope"
                :options="serviceRequestDistricts"
                v-model="serviceRequestDistrict"
                class="brand-select"
                placeholder="İlçe"
                :clearable="false"
              >
                <template slot="option" slot-scope="option">
                  {{ option.label }}
                </template>
                <template v-slot:no-options>İlçenizi bulamadık..</template>
              </v-select>

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestDistrict') }"
                >Lütfen ilçe seçiniz.</span
              >
            </div>
          </div>
          <div class="col-md-12 pd0">
            <div class="form-group brand-select">
              <v-select
                autocomplete="nope"
                :options="serviceRequestNeighborhoods"
                v-model="serviceRequestNeighborhood"
                class="brand-select"
                placeholder="Mahalle"
                :clearable="false"
              >
                <template slot="option" slot-scope="option">
                  {{ option.label }}
                </template>
                <template v-slot:no-options>İlçenizi bulamadık..</template>
              </v-select>

              <span
                class="error"
                :class="{
                  show: notValid.includes('serviceRequestNeighborhood'),
                }"
                >Lütfen mahalle seçiniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input"
                maxlength="25"
                placeholder="Cadde"
                v-model="serviceForm.serviceRequestMainStreet"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestMainStreet') }"
                >Lütfen cadde bilgisi giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input"
                maxlength="25"
                placeholder="Sokak"
                v-model="serviceForm.serviceRequestStreet"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestStreet') }"
                >Lütfen sokak bilgisi giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input"
                maxlength="25"
                placeholder="Apartman No"
                v-model="serviceForm.serviceRequestApartment"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestApartment') }"
                >Lütfen apartman bilgisi giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-6 pd0 pd-r-5">
            <div class="form-group">
              <input
                autocomplete="nope"
                type="text"
                class="brand-input"
                maxlength="25"
                placeholder="Daire"
                v-model="serviceForm.serviceRequestLivingRoom"
              />

              <span
                class="error"
                :class="{ show: notValid.includes('serviceRequestLivingRoom') }"
                >Lütfen daire numarası giriniz.</span
              >
            </div>
          </div>
          <div class="col-md-12 pd0 pd-r-5">
            <div class="form-group date">
              <DatePicker
                class="brand-input"
                placeholder="Tarih"
                format="MM/DD/YYYY"
                v-model="serviceForm.serviceRequestAppointmentDate"
                value-type="format"
              ></DatePicker>

              <span
                class="error"
                :class="{
                  show: notValid.includes('serviceRequestAppointmentDate'),
                }"
                >Lütfen tarih seçiniz.</span
              >
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <!-- Servis Formu -->

        <button
          type="button"
          class="brand-button"
          @click="addSerialNumber"
          v-if="besAccountList.length == 0 && step == 'second'"
        >
          İLERLE
          <Loading v-if="isProcessing"></Loading>
        </button>
      </div>
    </div>
    <div v-if="step == 'third'">
      <div v-if="!showServiceRequestForm">
        <div v-if="besAccountList.length != 0">
          <bes-account-list
            :besAccountList="besAccountList"
            @selectAccount="selectAccount"
            v-if="showBesAccount"
          ></bes-account-list>
          <span class="error" :class="{ show: notValid.includes('besAccount') }"
            >Lütfen bes hesabı seçiniz.</span
          >
        </div>
        <span class="error show" v-if="error != null">{{ error }}</span>
        <span v-if="tryingBes" class="alert mb-1">
          Değerli bayilerimiz; Baymaklife portalına seri kodu yükleyip, BES
          kazanımlarınızı gerçekleştirebilmeniz için BES hesabınızın olması
          zorunludur. Mevcut bir BES hesabınız var ise lütfen hesabınıza
          ekleyiniz. Eğer yok ise 0212 334 71 74 numaralı hattan Garanti BES
          Emeklilik çağrı merkezini arayarak BES hesabı oluşturmanızı rica
          ederiz.
        </span>

        <!-- 
        <button
          type="button"
          class="brand-button"
          @click="checkBesAccount"
          v-if="
            isCanBeLoadedAsPoint &&
              showBesAccount == false &&
              showBesButton == true &&
              step == 'third'
          "
        >
          BES YÜKLE
        </button>
        -->

        <button
          type="button"
          class="brand-button"
          @click="saveSerialNumber(true)"
          v-if="besAccountList.length != 0 && showBesAccount == true"
        >
          KAYDET
          <Loading v-if="isProcessing"></Loading>
        </button>
        <button
          type="button"
          class="brand-button"
          @click="saveSerialNumber(false)"
          v-if="isCanBeLoadedAsPoint && showBesAccount == false"
        >
          PUAN YÜKLE
          <Loading v-if="isProcessing"></Loading>
        </button>
      </div>
      <div v-if="successMessage">{{ successMessage }}</div>
    </div>
    <service-request-form
      :data="{
        code: code,
        baymakCityId: baymakCityId.baymakCityId,
        districtId: districtId.id,
        selectedBesAccount: selectedBesAccount,
        isCombiInstallationControlForm: isCombiInstallationControlForm,
        canCreateServiceForm: canCreateServiceForm,
      }"
      v-if="showServiceRequestForm"
    ></service-request-form>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import Loading from "@/components/loading";
import BesAccountList from "@/components/Bes-Account-List";
import { TheMask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/tr";
import ServiceRequestForm from "./Service-Request-Form.vue";

export default {
  name: "serialNumber",
  data() {
    return {
      code: "",
      baymakCityId: "",
      districtId: "",
      citys: [],
      districts: [],
      notValid: [],
      besAccountList: [],
      selectedBesAccount: 0,
      isProcessing: false,
      error: null,
      showBesButton: false,
      showBesAccount: false,
      showServiceRequestForm: false,
      successMessage: "",
      isPointUpload: sessionStorage.isPointUpload,
      isCanBeLoadedAsPoint: true,
      step: "first",
      canCreateServiceForm: true,
      showControlFormCheckbox: false,
      isCombiInstallationControlForm: false,
      serviceRequestAppointmentDate: null,
      serviceForm: {
        serviceRequestName: "",
        serviceRequestSurname: "",
        serviceRequestCityId: "",
        serviceRequestDistrictId: "",
        serviceRequestNeighborhoodId: "",
        serviceRequestMainStreet: "",
        serviceRequestStreet: "",
        serviceRequestApartment: "",
        serviceRequestLivingRoom: "",
        serviceRequestAppointmentDate: "",
        serviceRequestPhone: "",
        serviceRequestEmail: "",
      },
      serviceRequestCity: null,
      serviceRequestDistrict: null,
      serviceRequestNeighborhood: null,
      serviceRequestPhone: null,
      serviceRequestCitys: [],
      serviceRequestDistricts: [],
      serviceRequestNeighborhoods: [],
      tryingBes: false,
    };
  },
  components: {
    Loading,
    BesAccountList,
    ServiceRequestForm,
    TheMask,
    DatePicker,
  },

  created() {
    this.refreshAndCreate();
  },
  methods: {
    getCity: function(list, q) {
      return list.filter((item) =>
        item.label
          .toLocaleLowerCase("tr-TR")
          .includes(q.toLocaleLowerCase("tr-TR"))
      );
    },
    getDistricts: function(list, q) {
      return list.filter((item) =>
        item.label
          .toLocaleLowerCase("tr-TR")
          .includes(q.toLocaleLowerCase("tr-TR"))
      );
    },
    refreshAndCreate: function() {
      this.code = "";
      this.baymakCityId = "";
      this.tryingBes = false;
      this.districtId = "";
      this.citys = [];
      this.districts = [];
      this.notValid = [];
      this.besAccountList = [];
      this.selectedBesAccount = 0;
      this.isProcessing = false;
      this.error = null;
      this.successMessage = "";
      this.isPointUpload = sessionStorage.isPointUpload;
      this.showBesAccount = false;
      this.showBesButton = false;
      this.isCanBeLoadedAsPoint = true;
      this.canCreateServiceForm = true;
      this.step = "first";
      this.showServiceRequestForm = false;
      this.serviceRequestCitys = [];
      this.serviceRequestDistricts = [];
      this.serviceRequestNeighborhood = [];
      this.isCombiInstallationControlForm = false;
      this.serviceForm = {
        serviceRequestName: "",
        serviceRequestSurname: "",
        serviceRequestCityId: "",
        serviceRequestDistrictId: "",
        serviceRequestNeighborhoodId: "",
        serviceRequestMainStreet: "",
        serviceRequestStreet: "",
        serviceRequestApartment: "",
        serviceRequestLivingRoom: "",
        serviceRequestAppointmentDate: "",
        serviceRequestPhone: "",
        serviceRequestEmail: "",
      };

      this.serviceRequestCity = null;
      this.serviceRequestDistrict = null;
      this.serviceRequestNeighborhood = null;
      this.serviceRequestPhone = null;
      this.serviceRequestCitys = [];
      this.serviceRequestDistricts = [];
      this.serviceRequestNeighborhoods = [];

      HTTP.post("User/GetUserAuthorizedCity", {}).then((result) => {
        result.data.forEach((city) => {
          this.citys.push({
            id: city.id,
            baymakCityId: city.baymakCityId,
            cityId: city.cityId,
            label: city.cityTitle,
          });
          this.serviceRequestCitys.push({
            id: city.id,
            baymakCityId: city.baymakCityId,
            cityId: city.cityId,
            label: city.cityTitle,
          });
        });
      });
    },
    firstStep: function() {
      this.isProcessing = true;
      this.error = null;
      this.checkForm();
      if (this.notValid.length == 0) {
        let data = {
          serialNumber: this.code,
        };
        HTTP.post("Transaction/GetSerialInfo", data).then((result) => {
          let data = result.data.data;
          if (result.data.success) {
            if (data.ilkCalistirmaSorguSonucu.durum != 2) {
              this.canCreateServiceForm = false;
              this.showControlFormCheckbox = true;
              this.step = "second";
              this.isProcessing = false;
            } else {
              this.canCreateServiceForm = true;
              this.isProcessing = false;
              this.addSerialNumber();
              this.step = "third";
            }
          } else {
            this.isProcessing = false;
            this.error = result.data.errorMessage;
          }
        });
      } else {
        this.isProcessing = false;
      }
    },
    addSerialNumber: function() {
      this.isProcessing = true;
      this.checkForm();
      // this.checkServiceForm();

      if (
        this.showControlFormCheckbox &&
        !this.isCombiInstallationControlForm
      ) {
        this.notValid.push("isCombiInstallationControlForm");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "isCombiInstallationControlForm"
        );
      }

      if (this.notValid.length == 0) {
        const data = {
          serialNumber: this.code,
        };

        HTTP.post("Transaction/GetPivatePensionSystems", data).then(
          (result) => {
            this.isProcessing = false;
            if (!result.data.success) {
              this.error = result.data.data;
            }
            this.isCanBeLoadedAsPoint = result.data.isCanBeLoadedAsPoint;
            if (!this.isCanBeLoadedAsPoint) {
              this.error = result.data.errorMessage;
              return;
            }
            this.showBesButton = result.data.useBESSytem;
            if (!result.data.isScenarioComplete && result.data.success) {
              this.step = "third";
              this.besAccountList = result.data.privatePensionSystemsView.filter(
                (besAccount) => besAccount.statusTitle == "Aktif"
              );
              this.error = null;
            }

            if (result.data.isScenarioComplete && result.data.success) {
              this.successMessage = result.data.data;
            }
          }
        );
      } else {
        this.isProcessing = false;
      }
    },
    selectAccount: function(accountId) {
      this.selectedBesAccount = accountId;
    },
    saveSerialNumber: function(toBesAccount) {
      if (toBesAccount) {
        if (this.selectedBesAccount == 0) {
          this.notValid.push("besAccount");
        } else {
          this.notValid = this.notValid.filter((item) => item != "besAccount");
        }
      } else {
        this.selectedBesAccount = 0;
        this.notValid = this.notValid.filter((item) => item != "besAccount");
      }

      if (this.notValid.length == 0) {
        /*let data = {
          baymakCityId: this.baymakCityId.baymakCityId,
          districtId: this.districtId.id,
          serialNumber: this.code,
          privatePensionSystemId: this.selectedBesAccount,
          isCombiInstallationControlForm: this.isCombiInstallationControlForm
          // serviceRequestName: this.serviceForm.serviceRequestName,
          // serviceRequestSurname: this.serviceForm.serviceRequestSurname,
          // serviceRequestCityId: this.serviceRequestCity.baymakCityId,
          // serviceRequestDistrictId: this.serviceRequestDistrict
          //   .baymakDistrictId,
          // serviceRequestNeighborhoodId: this.serviceRequestNeighborhood
          //   .baymakId,
          // serviceRequestMainStreet: this.serviceForm.serviceRequestMainStreet,
          // serviceRequestStreet: this.serviceForm.serviceRequestStreet,
          // serviceRequestApartment: this.serviceForm.serviceRequestApartment,
          // serviceRequestLivingRoom: this.serviceForm.serviceRequestLivingRoom,
          // serviceRequestAppointmentDate: this.serviceForm
          //   .serviceRequestAppointmentDate,
          // serviceRequestPhone: this.serviceForm.serviceRequestPhone,
          // serviceRequestEmail: this.serviceForm.serviceRequestEmail
        };*/
        this.showServiceRequestForm = true;
      }
    },
    checkForm: function() {
      if (this.code.length == 0) {
        this.notValid.push("code");
      } else {
        this.notValid = this.notValid.filter((item) => item != "code");
      }
      if (this.baymakCityId.length == 0) {
        this.notValid.push("baymakCityId");
      } else {
        this.notValid = this.notValid.filter((item) => item != "baymakCityId");
      }
      if (this.districtId.length == 0) {
        this.notValid.push("districtId");
      } else {
        this.notValid = this.notValid.filter((item) => item != "districtId");
      }
    },
    checkServiceForm: function() {
      if (
        this.showControlFormCheckbox &&
        !this.isCombiInstallationControlForm
      ) {
        this.notValid.push("isCombiInstallationControlForm");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "isCombiInstallationControlForm"
        );
      }
      if (this.serviceForm.serviceRequestName.length == 0) {
        this.notValid.push("serviceRequestName");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestName"
        );
      }
      if (this.serviceForm.serviceRequestSurname.length == 0) {
        this.notValid.push("serviceRequestSurname");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestSurname"
        );
      }

      if (!this.serviceRequestCity) {
        this.notValid.push("serviceRequestCity");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestCity"
        );
      }
      if (!this.serviceRequestDistrict) {
        this.notValid.push("serviceRequestDistrict");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestDistrict"
        );
      }
      if (!this.serviceRequestNeighborhood) {
        this.notValid.push("serviceRequestNeighborhood");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestNeighborhood"
        );
      }

      if (this.serviceForm.serviceRequestMainStreet.length == 0) {
        this.notValid.push("serviceRequestMainStreet");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestMainStreet"
        );
      }

      if (this.serviceForm.serviceRequestStreet.length == 0) {
        this.notValid.push("serviceRequestStreet");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestStreet"
        );
      }

      if (this.serviceForm.serviceRequestApartment.length == 0) {
        this.notValid.push("serviceRequestApartment");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestApartment"
        );
      }

      if (this.serviceForm.serviceRequestLivingRoom.length == 0) {
        this.notValid.push("serviceRequestLivingRoom");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestLivingRoom"
        );
      }

      if (this.serviceForm.serviceRequestAppointmentDate.length == 0) {
        this.notValid.push("serviceRequestAppointmentDate");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestAppointmentDate"
        );
      }

      if (this.serviceForm.serviceRequestPhone.length < 7) {
        this.notValid.push("serviceRequestPhone");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestPhone"
        );
      }

      if (!this.validEmail(this.serviceForm.serviceRequestEmail)) {
        this.notValid.push("serviceRequestEmail");
      } else {
        this.notValid = this.notValid.filter(
          (item) => item != "serviceRequestEmail"
        );
      }
    },
    contractModal: function() {
      EventBus.$emit("contractModal", true);
    },
    validEmail: function(email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
      /* eslint-disable */
    },
    checkBesAccount: function() {
      if (this.besAccountList.length === 0) {
        this.tryingBes = true;
      } else {
        this.showBesAccount = true;
      }
    },
  },
  watch: {
    baymakCityId: function() {
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.baymakCityId.cityId,
      }).then((result) => {
        this.district = "";
        this.districts = [];
        result.data.forEach((item) => {
          this.districts.push({ id: item.baymakDistrictId, label: item.title });
        });
      });
    },
    serviceRequestCity: function() {
      HTTP.post("Districts/GetListByCityId", {
        cityId: this.serviceRequestCity.cityId,
      }).then((result) => {
        this.serviceForm.serviceRequestDistrictId = null;
        this.serviceRequestDistrict = null;
        this.serviceRequestDistricts = [];
        this.serviceForm.serviceRequestNeighborhoodId = null;
        this.serviceRequestNeighborhood = null;
        this.serviceRequestNeighborhoods = [];
        result.data.forEach((item) => {
          this.serviceRequestDistricts.push({
            baymakDistrictId: item.baymakDistrictId,
            id: item.id,
            label: item.title,
          });
        });
      });
    },
    serviceRequestDistrict: function() {
      if (this.serviceRequestDistrict) {
        HTTP.post("Neighborhood/GetListByDistrictId", {
          districtId: this.serviceRequestDistrict.id,
        }).then((result) => {
          this.serviceForm.serviceRequestNeighborhoodId = null;
          this.serviceRequestNeighborhood = null;
          this.serviceRequestNeighborhoods = [];
          result.data.forEach((item) => {
            this.serviceRequestNeighborhoods.push({
              id: item.id,
              baymakId: item.baymakNeighborhoodId,
              label: item.title,
            });
          });
        });
      }
    },
    isCombiInstallationControlForm: function() {
      if (this.isCombiInstallationControlForm) {
        this.contractModal();
      }
    },
  },
  mounted() {
    EventBus.$on("clicked", (val) => {
      if (val) {
        this.refreshAndCreate();
      }
    });
    /*eslint-disable*/
    $(function() {
      $('[data-toggle="popover"]').popover({
        trigger: "hover",
      });
    });
    /*eslint-disable*/
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors";
.h3 {
  margin-bottom: 20px !important;
}
.code {
  .brand-input {
    height: 50px !important;
    font-weight: bold !important;
    text-align: center !important;
    background: $white !important;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.31) !important;
  }
}
.brand-button {
  margin-bottom: 10px;
  width: 100%;
}
.form-group {
  position: relative;
  .f-info {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ccc;
    cursor: pointer;
  }
  .s-info {
    top: 15px;
    right: 40px;
    font-size: 14px;
  }
  .popover {
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
  }
}
.text-left {
  text-align: left !important;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}
.besinfo {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #f0f0f0;
  cursor: pointer;
}
.serialnumber {
  .small {
    margin-top: -10px;
    display: block;
  }
  .small-2 {
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .bessmall {
    margin-top: -5px;
    font-size: 11px;
  }
}
.contractform {
  .btn-radio {
    font-size: 13px;
  }
}
.service-form {
  .h4 {
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
  }
}
.date {
  .brand-input {
    width: 100% !important;
  }
}
.mb-1 {
  margin-bottom: 10px;
  display: block;
}
</style>
